.section {
    max-width: 1000px;
    padding: 56px 32px;
}
.grey-background {
    background-color: #f3f3f3;
}
.image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    cursor: pointer;
}
.flexItem {
    display: flex;
}
.equipment-padding {
    padding-right: 16px;
}
.icon-color {
    color: #41a9bd;
}
.price-item-container {
    height: 75px;
}
.price-item {
    display: flex;
    justify-content: center;
    padding: 6px 16px;
    background-color: #EFEEEB70;
    margin: 8px;

    @media (max-width: 540px) {
        padding: 6px 8px;
        margin: 4px;
    }
    @media (max-width: 439px) {
        padding: 6px 2px;
        margin: 2px;
    }
}
#price-note {
    margin-top: 32px;
}
.calendar {
    width: 1000px;
    height: 600px;

    @media (max-width: 1020px) {
        width: 900px;
    }
    @media (max-width: 820px) {
        width: 700px;
    }
    @media (max-width: 718px) {
        width: 600px;
    }
    @media (max-width: 618px) {
        width: 500px;
    }
    @media (max-width: 518px) {
        width: 400px;
    }
    @media (max-width: 418px) {
        width: 300px;
    }
    @media (max-width: 318px) {
        width: 250px;
    }
}
div .abouthouse-container {
    display: flex;
    @media (max-width: 800px) {
        display: block;

        .left-side {
            max-width: 100%;
            
        }
        .right-side {
            max-width: 450px;
            display: flex;
            justify-content: center;
        }
    }
}

div .location-container {
    display: flex;
    @media (max-width: 800px) {
        display: block;

        .left-side {
            max-width: 100%;
            
        }
        .right-side {
            max-width: 100%;
        }
    }
}

.title {
    padding-bottom: 32px;
}
#price-section {
    .price-text {
        font-size: 1rem;
    
        @media (max-width: 647px) {
            font-size: 0.8rem;
        }

        @media (max-width: 572px) {
            font-size: 0.7rem;
        }
    }

    .price-title {
        font-size: 1rem;
    
        @media (max-width: 647px) {
            font-size: 0.8rem;
        }

        @media (max-width: 572px) {
            font-size: 0.7rem;
        }
    }
}

.equipment-group {
    padding-top: 32px;
}