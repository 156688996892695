.preview-dialog-content-container {
    height: 100vh;
}

.stepper-image {
    max-height: 100vh;
    max-width: 400;
    overflow: hidden;
    display: block;
    object-fit: contain;
}

#preview-dialog-content {
    padding: 0;

    &:first-child {
        padding-top: 0;
    }
}

.image-container {
    background-color: #000;
    height: 100%;
}

#preview-close-button {
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 1;
    color: #fff;
    background-color: #4c4c4c;
    opacity: 0.5;
}

.mobile-stepper-container {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    .root {
        width: 100%;
        background: transparent;
    }
}

#right-button {
    color: #fff;
    background-color: #4c4c4c;
    margin: auto 0;
    height: 40px;
    min-width: 40px;
    opacity: 0.4;
    position: absolute;
    left: 12px;
}

#left-button {
    color: #fff;
    background-color: #4c4c4c;
    right: 12px;
    position: absolute;
    height: 40px;
    min-width: 40px;
    opacity: 0.5;
}

.MuiMobileStepper-root > .MuiMobileStepper-dots {
    display: none;
}